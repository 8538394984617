<template>
  <!-- start for mobile -->
  <v-dialog
    v-if="this.$root.mobile"
    v-model="dialog"
    :fullscreen="$root.mobile"
    transition="dialog-bottom-transition"
    :width="$root.mobile ? '100%' : '500px'"
    persistent
    style="min-height:60% !important"
    hide-overlay
  >
    <v-card :disabled="askStarted">
      <v-toolbar
        flat
        dark
        color="primary"
        width="100%"
        style="position:fixed;z-index:1000"
      >
        <v-btn icon dark @click="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{ $t("MF.prescription.new") }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text style="min-height: calc(100vh - 56px); padding-bottom:70px">
        <v-form
          ref="prescriptionForm"
          lazy-validation
          v-model="validmobile"
          style="min-height:inherit"
        >
          <div
            class=" mt-0 mb-4 d-flex flex-column"
            style="min-height:inherit;padding-top:70px"
          >
            <div class="flex-grow-1">
              <span class="font-size-h4">Medications</span>
              <div
                v-for="(med, idx) in prescriptionForm.presMeds"
                style="width:30rem;"
                :key="idx"
                class="listitem mb-2"
              >
                <v-list-item disabled>
                  <v-list-item-avatar size="47" color="#F2F7FD">
                    <v-img
                      v-if="med.type == 'injection'"
                      src="/media/svg/icons/Doctory/icons-syringe.svg"
                      height="20"
                      width="20"
                      contain
                    />
                    <v-img
                      v-else
                      src="/media/svg/icons/Doctory/icons-pills.svg"
                      height="20"
                      width="20"
                      contain
                    />
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title
                      ><div class="listitemtitle">
                        {{ med.intake }} {{ med.type }} of {{ med.dosage }}
                        <span class="font-weight-bold">{{
                          med.medicine.name
                        }}</span>
                      </div></v-list-item-title
                    >
                    <v-list-item-subtitle>
                      <span
                        v-for="(time, index) in med.times"
                        :key="index"
                        class="font-size-h6"
                        >{{ time.time }}
                        <span v-if="index < med.times.length">, </span></span
                      >

                      <span class="font-size-h6"> {{ med.frequancy }}</span>
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                      <div class="listitemsubtitle">
                        Expires
                        {{ med.end_date | moment("dddd, MMMM Do YYYY") }}
                      </div>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <a
                      class="btn btn-sm btn-icon btn-light-danger mr-2"
                      @click="removeMed(m)"
                    >
                      <em class="far fa-trash-alt"></em>
                    </a>
                  </v-list-item-action>
                </v-list-item>
              </div>

              <v-btn
                class="my-3"
                block
                rounded
                color="primary"
                @click="addpresform = true"
              >
                Add Medication
              </v-btn>
            </div>
            <div>
              <span class="font-size-h4">Additional Instructions</span>
              <v-textarea
                name="entry_body"
                outlined
                label="Enter Details here"
                auto-grow
                v-model="prescriptionForm.notes"
                :rules="notesRules"
                class="mt-4"
              ></v-textarea>
            </div>
          </div>
        </v-form>
      </v-card-text>
      <v-card-actions
        style="position:absolute;bottom:0px;left:0;right:0; width:100%"
        class="pa-1 ma-0"
      >
        <v-row class="ma-0 pa-0 white">
          <v-col>
            <v-btn large rounded text color=" primary" block @click="close()">
              {{ $t("QUES.cancel") }}
            </v-btn>
          </v-col>
          <v-col>
            <v-btn
              large
              block
              color="primary white--text"
              rounded
              @click="submit"
              :disabled="prescriptionForm.presMeds.length == 0"
            >
              {{ $t("MF.consultation.send") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
      <v-bottom-sheet v-model="addpresform" scrollable>
        <v-card>
          <v-toolbar flat dark color="white" class="text-primary py-2">
            <v-toolbar-title>
              Add Medication
            </v-toolbar-title>
            <v-spacer />

            <v-btn icon dark color="primary" @click="addpresform = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <!-- row one -->
            <div class="row">
              <div class="col-sm-6 pt-0 pb-0">
                <v-combobox
                  v-model="prescriptionForm.medicine"
                  clearable
                  validate-on-blur
                  outlined
                  :items="meds"
                  :loading="medsLoading"
                  :filter="medsFilter"
                  :rules="[v => !!v || 'Medicine is required']"
                  label="Medicine"
                  item-text="name"
                  item-value="name"
                  return-object
                  @change="isnew"
                >
                  <template v-slot:selection="data">
                    {{ data.item.name }}
                  </template>
                  <template v-slot:item="data">
                    {{ data.item.name }}
                  </template>
                </v-combobox>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-6 pt-0 pb-0">
                <v-text-field
                  name="entry_body"
                  outlined
                  validate-on-blur
                  label="Dosage"
                  v-model="prescriptionForm.dosage"
                  :rules="dosageRules"
                ></v-text-field>
              </div>
            </div>
            <!-- end row one -->
            <!-- row two -->
            <div class="row">
              <div class="col-lg-6 col-md-6 col-sm-6 pt-0 pb-0">
                <v-text-field
                  name="entry_body"
                  outlined
                  validate-on-blur
                  label="Quantity per singal intake"
                  v-model="prescriptionForm.intake"
                  :rules="[v => !!v || 'Quantity is required']"
                ></v-text-field>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-6 pt-0 pb-0">
                <v-autocomplete
                  v-model="prescriptionForm.type"
                  clearable
                  validate-on-blur
                  outlined
                  :items="intakeTypes"
                  :loading="typesLoading"
                  :filter="typeFilter"
                  :rules="[v => !!v || 'Intake Type is required']"
                  label="Form"
                  item-text="name"
                  item-value="name"
                  return-object
                >
                  <template v-slot:selection="data">
                    {{ data.item }}
                  </template>
                  <template v-slot:item="data">
                    {{ data.item }}
                  </template>
                </v-autocomplete>
              </div>
            </div>
            <!-- end row two -->
            <!-- row three -->
            <div class="row">
              <div class="col-lg-12 col-md-12 col-sm-12 pt-0 pb-0">
                <v-autocomplete
                  v-model="prescriptionForm.times"
                  clearable
                  outlined
                  validate-on-blur
                  :items="timesList"
                  :loading="timesLoading"
                  :filter="timesFilter"
                  :rules="[v => !!v.length || 'Times Per Day is required']"
                  label="When"
                  item-text="time"
                  item-value="time"
                  multiple
                  return-object
                >
                  <template v-slot:selection="data">
                    <v-chip
                      v-bind="data.attrs"
                      :input-value="data.selected"
                      close
                      color="primary"
                      @click="data.select"
                      @click:close="remove(data.item)"
                    >
                      {{ data.item.time }}
                    </v-chip>
                  </template>
                  <template v-slot:item="data">
                    {{ data.item.time }}
                  </template>
                </v-autocomplete>
                <v-autocomplete
                  v-model="prescriptionForm.frequancy"
                  clearable
                  outlined
                  validate-on-blur
                  :items="freqs"
                  :loading="freqsLoading"
                  :filter="freqsFilter"
                  :rules="[v => !!v || 'Frequency is required']"
                  label="Every"
                  item-text="name"
                  item-value="name"
                  return-object
                >
                  <template v-slot:selection="data">
                    {{ data.item }}
                  </template>
                  <template v-slot:item="data">
                    {{ data.item }}
                  </template>
                </v-autocomplete>

                <v-menu
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="prescriptionForm.end_date"
                      readonly
                      outlined
                      v-bind="attrs"
                      v-on="on"
                      label="Expires On"
                      :rules="[v => !!v || 'Expiration Date is required']"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="prescriptionForm.end_date"
                    @input="menu2 = false"
                  ></v-date-picker>
                </v-menu>
              </div>
            </div>
            <!-- end row three -->
            <v-btn
              block
              color="primary"
              elevation="0"
              rounded
              :disabled="!(validmobile && isformfilled)"
              @click="
                addToPrescription();
                addpresform = false;
              "
              >Add to Prescription</v-btn
            >
          </v-card-text>
        </v-card>
      </v-bottom-sheet>
      <v-dialog v-model="askStarted" hide-overlay persistent width="300">
        <v-card color="primary" dark>
          <v-card-text>
            Please stand by
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-card>
  </v-dialog>
  <!-- end for mobile -->

  <v-card elevation="0" class="pr-4 pl-4" :disabled="enableForm" v-else>
    <v-form
      :disabled="postingpres"
      ref="prescriptionForm"
      lazy-validation
      v-model="validweb"
    >
      <div class="row mt-0 mb-4">
        <div
          class="col-sm-6 p-5"
          style="border-radius: 15px;box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.10);background-color: #ffffff;"
        >
          <!-- row one -->
          <div class="row">
            <div class="col-sm-6 pt-0 pb-0">
              <v-combobox
                v-model="prescriptionForm.medicine"
                clearable
                outlined
                :items="meds"
                :loading="medsLoading"
                :filter="medsFilter"
                :rules="[v => !!v || 'Medicine is required']"
                label="Medicine"
                item-text="name"
                item-value="name"
                validate-on-blur
                return-object
                @change="isnew"
              >
                <template v-slot:selection="data">
                  {{ data.item.name }}
                </template>
                <template v-slot:item="data">
                  {{ data.item.name }}
                </template>
              </v-combobox>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 pt-0 pb-0">
              <v-text-field
                name="entry_body"
                outlined
                label="Dosage"
                validate-on-blur
                v-model="prescriptionForm.dosage"
                :rules="[v => !!v || 'Dosage is required']"
              ></v-text-field>
            </div>
          </div>
          <!-- end row one -->
          <!-- row two -->
          <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-6 pt-0 pb-0">
              <v-text-field
                name="entry_body"
                outlined
                validate-on-blur
                label="Quantity per singal intake"
                v-model="prescriptionForm.intake"
                :rules="[v => !!v || 'Quantity is required']"
              ></v-text-field>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 pt-0 pb-0">
              <v-autocomplete
                v-model="prescriptionForm.type"
                clearable
                outlined
                :items="intakeTypes"
                :loading="typesLoading"
                :filter="typeFilter"
                :rules="[v => !!v || 'Intake Type is required']"
                label="Form"
                item-text="name"
                item-value="name"
                validate-on-blur
                return-object
              >
                <template v-slot:selection="data">
                  {{ data.item }}
                </template>
                <template v-slot:item="data">
                  {{ data.item }}
                </template>
              </v-autocomplete>
            </div>
          </div>
          <!-- end row two -->
          <!-- row three -->
          <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 pt-0 pb-0">
              <v-autocomplete
                v-model="prescriptionForm.times"
                clearable
                outlined
                :items="timesList"
                :loading="timesLoading"
                :filter="timesFilter"
                :rules="[v => !!v.length || 'Times Per Day is required']"
                label="When"
                item-text="time"
                item-value="time"
                multiple
                validate-on-blur
                return-object
              >
                <template v-slot:selection="data">
                  <v-chip
                    v-bind="data.attrs"
                    :input-value="data.selected"
                    close
                    color="primary"
                    @click="data.select"
                    @click:close="remove(data.item)"
                  >
                    {{ data.item.time }}
                  </v-chip>
                </template>
                <template v-slot:item="data">
                  {{ data.item.time }}
                </template>
              </v-autocomplete>
              <v-autocomplete
                v-model="prescriptionForm.frequancy"
                clearable
                outlined
                validate-on-blur
                :items="freqs"
                :loading="freqsLoading"
                :filter="freqsFilter"
                :rules="[v => !!v || 'Frequency is required']"
                label="Every"
                item-text="name"
                item-value="name"
                return-object
              >
                <template v-slot:selection="data">
                  {{ data.item }}
                </template>
                <template v-slot:item="data">
                  {{ data.item }}
                </template>
              </v-autocomplete>
              {{ prescriptionForm.end_date }}
              <v-menu
                v-model="menu2"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="prescriptionForm.end_date"
                    readonly
                    outlined
                    v-bind="attrs"
                    v-on="on"
                    label="Expires On"
                    :rules="[v => !!v || 'Expiration Date is required']"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="prescriptionForm.end_date"
                  @input="menu2 = false"
                ></v-date-picker>
              </v-menu>
            </div>
          </div>
          <!-- end row three -->
          <v-btn
            block
            color="primary"
            elevation="0"
            rounded
            x-large
            :disabled="!(validweb && isformfilled)"
            @click="addToPrescription()"
            >Add Medication to Prescription</v-btn
          >
        </div>
        <div class="col-sm-6">
          <span class="font-size-h4">Medications</span>
          {{ prescriptionForm }}
          <div style="min-height: 200px">
            <div
              v-for="(m, idx) in prescriptionForm.presMeds"
              :key="idx"
              class="d-flex align-items-center mt-2 bg-primary rounded p-5"
            >
              <div class="d-flex flex-column flex-grow-1 font-weight-bold">
                <span class="text-light mb-1 font-size-lg">
                  {{ m.quantity }} {{ m.type }} of {{ m.dosage }}
                  {{ m.medicine.name }}
                </span>
                <span class="text-light font-size-sm"
                  ><span v-for="(t, index) in m.times" :key="index"
                    >{{ t.time }}
                    <span v-if="index < m.times.length - 1">, </span></span
                  ></span
                >
                <span class="text-light font-size-sm">{{ m.frequancy }}</span>
                <span class="text-light font-size-sm"
                  >Expires on: {{ m.end_date }}</span
                >
              </div>
              <div class="btn-group">
                <a
                  class="btn btn-sm btn-icon btn-light-danger mr-2"
                  @click="removeMed(m)"
                >
                  <em class="far fa-trash-alt"></em>
                </a>
              </div>
            </div>
            <div
              v-if="prescriptionForm.presMeds.length == 0"
              class="text-muted font-size-sm text-center"
              style="line-height: 200px; width: 100%"
            >
              <span>👈 Add Medications from here</span>
            </div>
          </div>

          <span class="font-size-h4">Additional Instructions</span>
          <v-textarea
            name="entry_body"
            outlined
            label="Enter Details here"
            auto-grow
            v-model="prescriptionForm.notes"
            :rules="notesRules"
            class="mt-4"
          ></v-textarea>
          <v-btn
            class="primary mb-0"
            block
            absolute
            bottom
            elevation="0"
            rounded
            x-large
            centered
            @click="submit"
            :loading="postingpres"
            :disabled="prescriptionForm.presMeds.length == 0"
            >Submit</v-btn
          >
        </div>
      </div>
    </v-form>
    <v-snackbar v-model="error" :multi-line="true" :top="true" :timeout="6000">
      {{ errorMsg }}
      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="error = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
import ApiService from "@/core/services/api.service";

export default {
  name: "PrescriptionForm",
  props: ["mfid", "dialog"],
  data() {
    return {
      error: false,
      errorMsg: "",
      //arrays from server
      meds: [],
      freqs: [],
      timesList: [],
      intakeTypes: [],
      //loading statuses
      medsLoading: true,
      freqsLoading: true,
      timesLoading: true,
      typesLoading: true,
      askStarted: false,
      validmobile: true,
      addpresform: false,
      validweb: true,
      notesRules: [
        v => !!v || "Notes is required",
        v => (v && v.trim().length) || "Notes Cant be empty"
      ],
      dosageRules: [
        v => !!v || "Dosage is required",
        v => (v && v.trim().length) || "Dosage Cant be empty"
      ],

      prescriptionForm: {
        notes: "",
        medicine: "",
        dosage: "",
        intake: "",
        type: "",
        start_date: new Date().toISOString().substr(0, 10),
        end_date: "",
        // prescription: "",
        frequancy: "",
        times: [],
        presMeds: []
      },

      //datepicker
      menu2: false,
      postingpres: false
    };
  },

  mounted() {
    this.getMeds();
    this.getFreq();
    this.getTimes();
    this.getIntakeType();
  },
  methods: {
    isnew() {
      if (typeof this.prescriptionForm.medicine == "string") {
        this.prescriptionForm.medicine = {
          id: -1,
          name: this.prescriptionForm.medicine
        };
      }
    },
    async getMeds() {
      try {
        const { data } = await ApiService.get(
          "items",
          "medicine?fields=id,name"
        );
        this.meds = data.data;
        this.medsLoading = false;
      } catch (error) {
        // console.log(error);
        this.error = error;
      }
      // console.log(this.meds);
    },
    async getFreq() {
      try {
        const { data } = await ApiService.get(
          "fields",
          "prescription_meds/frequancy?fields=options"
        );
        for (var i in data.data.options.choices)
          this.freqs.push(data.data.options.choices[i]);
        this.freqsLoading = false;
      } catch (error) {
        // console.log(error);
        this.error = error;
      }
    },
    async getIntakeType() {
      try {
        const { data } = await ApiService.get(
          "fields",
          "prescription_meds/type?fields=options"
        );
        for (var i in data.data.options.choices) {
          this.intakeTypes.push(data.data.options.choices[i]);
        }
        this.typesLoading = false;
      } catch (error) {
        // console.log(error);
        this.error = error;
      }
    },
    async getTimes() {
      try {
        const { data } = await ApiService.get(
          "items",
          "prescription_intake_time?fields=id,time,range_start,range_end"
        );
        this.timesList = data.data;
        this.timesLoading = false;
      } catch (error) {
        // console.log(error);
        this.error = error;
      }
    },
    remove(item) {
      const index = this.times.indexOf(item);
      if (index >= 0) this.times.splice(index, 1);
    },
    removeMed(item) {
      const index = this.prescriptionForm.presMeds.indexOf(item);
      if (index >= 0) this.prescriptionForm.presMeds.splice(index, 1);
    },
    medsFilter(item, queryText) {
      return item.name.toLowerCase().includes(queryText.toLowerCase());
    },
    freqsFilter(item, queryText) {
      return item.toLowerCase().includes(queryText.toLowerCase());
    },
    typeFilter(item, queryText) {
      return item.toLowerCase().includes(queryText.toLowerCase());
    },
    timesFilter(item, queryText) {
      return item.time.toLowerCase().includes(queryText.toLowerCase());
    },

    async addToPrescription() {
      if (this.prescriptionForm.medicine.id == -1) {
        let res = await this.$prescription.createMedicine({
          name: this.prescriptionForm.medicine.name
        });
        this.prescriptionForm.medicine = res.data.data;
      }
      var toAdd = {};
      toAdd["medicine"] = this.prescriptionForm.medicine;
      toAdd["dosage"] = this.prescriptionForm.dosage;
      toAdd["intake"] = this.prescriptionForm.intake;
      toAdd["frequancy"] = this.prescriptionForm.frequancy;
      toAdd["times"] = this.prescriptionForm.times;
      toAdd["type"] = this.prescriptionForm.type;
      toAdd["start_date"] = this.prescriptionForm.start_date;
      toAdd["end_date"] = this.prescriptionForm.end_date;

      this.prescriptionForm.presMeds.push(toAdd);

      this.prescriptionForm.medicine = "";
      this.prescriptionForm.dosage = "";
      this.prescriptionForm.intake = "";
      this.prescriptionForm.frequancy = "";
      this.prescriptionForm.type = "";
      this.prescriptionForm.times = [];
      this.prescriptionForm.start_date = new Date().toISOString().substr(0, 10);
      this.prescriptionForm.end_date = "";
      this.$refs.prescriptionForm.reset();
    },
    async submit() {
      try {
        this.postingpres = true;
        let med_payload;
        let time_payload;
        let med_req;
        let med_times;
        // let time_req;

        const pres_payload = {
          notes: this.prescriptionForm.notes,
          medical_file: this.mfid,
          via: this.$root.viaid
        };

        // create prescription
        this.askStarted = true;
        const pres_req = await ApiService.post(
          "items/md_prescription?fields=id",
          pres_payload
        );

        for (let med of this.prescriptionForm.presMeds) {
          med_payload = {
            medicine: med.medicine.id,
            dosage: med.dosage,
            intake: med.intake,
            frequancy: med.frequancy,
            type: med.type,
            start_date: med.start_date,
            end_date: med.end_date,
            perscription: pres_req.data.data.id
          };

          // insert medication
          med_req = await ApiService.post(
            "items/prescription_meds?fields=id",
            med_payload
          );

          // insert medication time
          med_times = med.times;
          for (let time of med_times) {
            time_payload = {
              prescription_meds_id: med_req.data.data.id,
              prescription_intake_time_id: time.id
            };

            await ApiService.post(
              "items/meds_time?fields=created_on",
              time_payload
            );
            this.askStarted = false;
          }
        }
        this.prescriptionForm.presMeds = [];
        this.$refs.prescriptionForm.reset();
        this.postingpres = false;
        this.$emit("backToStepOneAndToast", "Added Prescription Successfully");
      } catch (error) {
        this.errorMsg = error;
        this.error = true;
        this.postingpres = false;
      }
    },
    close() {
      this.$emit("closeClicked", false);
    }
  },
  computed: {
    enableForm() {
      return (
        this.medsLoading ||
        this.typesLoading ||
        this.freqsLoading ||
        this.timesLoading
      );
    },
    isformfilled() {
      return (
        this.prescriptionForm.medicine != "" &&
        this.prescriptionForm.dosage != "" &&
        this.prescriptionForm.intake != "" &&
        this.prescriptionForm.frequancy != "" &&
        this.prescriptionForm.type != "" &&
        this.prescriptionForm.times != [] &&
        this.prescriptionForm.end_date != ""
      );
    }
  }
};
</script>
